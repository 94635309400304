import { cn } from '@/lib/utils';
import defaultImg from "@public/logos/logo-square-color.png";
import { useInViewport } from 'ahooks';
import React, { forwardRef, useEffect, useRef, useState, useCallback } from 'react';

const LazyImg = React.memo(forwardRef(({ src = "", onClick = () => { }, className = "", style = {} }, ref) => {
    const ImageRef = useRef(null);

    const [inViewport] = useInViewport(ImageRef);
    const [imageSrc, setImageSrc] = useState(() => defaultImg);

    useEffect(() => {
        if (inViewport && imageSrc === defaultImg) {
            setImageSrc(src);
        }
    }, [inViewport, src, imageSrc]);

    const handleClick = useCallback(onClick, [onClick]);

    return <img
        style={style}
        onClick={handleClick}
        src={imageSrc}
        ref={ImageRef}
        className={cn("flex flex-col justify-center items-center", className)}
    />;
}));

export default LazyImg;
